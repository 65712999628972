import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client';
import Router from 'next/router';
import cookie from 'cookie';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';

// This apollo link largely follows the example at https://gist.github.com/alfonmga/9602085094651c03cd2e270da9b2e3f7

const { publicRuntimeConfig } = getConfig() ?? {};
const { NEXT_PUBLIC_REFRESH_TOKEN_COOKIE } = publicRuntimeConfig ?? {};
const LOGIN_PAGE_PATH_NAME = '/login';

const isSSR = typeof window === 'undefined';

const redirectToLogin = () => {
    // eslint-disable-next-line no-console
    if (!isSSR) {
        if (document.cookie) {
            document.cookie = cookie.serialize(
                NEXT_PUBLIC_REFRESH_TOKEN_COOKIE,
                '',
                {
                    maxAge: -1
                }
            );
        }

        if (Router.asPath !== LOGIN_PAGE_PATH_NAME) {
            Router.push({
                pathname: LOGIN_PAGE_PATH_NAME,
                query: {
                    redirect: Router.asPath
                }
            });
        }
    }
};

export const fetchNewAccessToken = async () => {
    const response = await fetch('/api/auth/refresh', {
        method: 'POST',
        credentials: 'include'
    });

    if (response.ok) {
        return response;
    }

    // convert non-2xx HTTP responses into errors:
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};

let isFetchingToken = false;
let tokenSubscribers = [];

const subscribeTokenRefresh = callback => {
    tokenSubscribers.push(callback);
};

const onTokenRefreshed = err => {
    tokenSubscribers.map(callback => callback(err));
};

export const refreshTokenLink = getNewAccessToken =>
    onError(
        ({ graphQLErrors, networkError, operation, response, forward }) =>
            new Observable(async observer => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(async graphQLError => {
                        if (
                            ['UNAUTHENTICATED', 'TOKEN_IS_BLANK'].includes(
                                graphQLError?.extensions?.code
                            )
                        ) {
                            const retryRequest = () => {
                                const subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer)
                                };
                                return forward(operation).subscribe(subscriber);
                            };

                            if (!isFetchingToken) {
                                isFetchingToken = true;

                                try {
                                    // Just needed for testing
                                    const refreshAccessToken =
                                        getNewAccessToken ??
                                        fetchNewAccessToken;

                                    await refreshAccessToken();

                                    // we've got a valid accessToken cookie
                                    isFetchingToken = false;
                                    onTokenRefreshed();
                                    tokenSubscribers = [];

                                    return retryRequest();
                                } catch (e) {
                                    onTokenRefreshed(
                                        new Error(
                                            'Unable to refresh access token'
                                        )
                                    );

                                    tokenSubscribers = [];
                                    isFetchingToken = false;

                                    redirectToLogin();

                                    return observer.error(graphQLError);
                                }
                            }

                            const tokenSubscriber = new Promise(resolve => {
                                // eslint-disable-next-line consistent-return
                                subscribeTokenRefresh(errRefreshing => {
                                    if (!errRefreshing) {
                                        return resolve(retryRequest());
                                    }
                                });
                            });

                            return tokenSubscriber;
                        }

                        return observer.error(graphQLError);
                    });
                }

                if (networkError) {
                    if (networkError.statusCode === 401) {
                        redirectToLogin();
                    }

                    return observer.error(networkError);
                }

                // Nothing happened
                if (!graphQLErrors && !networkError) {
                    return observer.next(response);
                }

                return null;
            })
    );
