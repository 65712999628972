import { gql } from '@apollo/client';

export const typeDefs = gql`
    extend type Query {
        isLoggedIn: Boolean!
    }

    type CurrentUser {
        jti: String
        exp: Int
        nbf: Int
        iat: Int
        iss: String
        aud: String
        sub: String
        typ: String
        azp: String
        auth_time: Int
        session_state: String
        acr: String
        scope: String
        email_verified: Boolean
        preferred_username: String
        email: String
        # user_tenant: [String]
        resource_access: ResourceAccess
        realm_access: RealmAccess
        # allowedorigins: [String]
    }
`;
