import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() ?? {};
const { NEXT_PUBLIC_DATA_GRAPH_HOST } = publicRuntimeConfig ?? process.env;

module.exports = {
    client: {
        includes: ['./src/pages/**/*.js'],
        service: {
            url: NEXT_PUBLIC_DATA_GRAPH_HOST ?? 'http://localhost:4000',
            name: 'Neptune Data Graph',
            skipSSLValidation: true
        }
    }
};
