import { ApolloClient, createHttpLink, from, split } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'isomorphic-unfetch';
import { WebSocketLink } from '@apollo/client/link/ws';
import getConfig from 'next/config';

import pkg from '../package.json';

import { resolvers, typeDefs } from './state';
import { refreshTokenLink } from './lib/apollo-refresh-token-link';
import { errorLogLink } from './lib/apollo-error-log-link';
import { generateTraceParent } from './lib/tracing';

import apolloConfig from '../apollo.config';

const { publicRuntimeConfig } = getConfig() ?? {};
const {
    NEXT_PUBLIC_DATA_GRAPH_SUBSCRIPTION_HOST,
    NEXT_PUBLIC_ENABLE_APOLLO_EXTENSION
} = publicRuntimeConfig ?? {};

const shouldConnectDevTools = JSON.parse(
    NEXT_PUBLIC_ENABLE_APOLLO_EXTENSION ?? 'false'
);

const wsLink = process.browser
    ? new WebSocketLink({
          uri: NEXT_PUBLIC_DATA_GRAPH_SUBSCRIPTION_HOST,
          options: {
              reconnect: true,
              timeout: 10000,
              connectionParams: {
                  accessToken: 'foo'
              }
          }
      })
    : () => {};

export default function createApolloClient(initialState, ctx) {
    const httpLink = createHttpLink({
        uri: apolloConfig.client.service.url,
        credentials: 'include',
        fetch
    });

    const traceLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                traceparent: generateTraceParent()
            }
        });
        return forward(operation);
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink
    );

    const cache = new InMemoryCache({
        typePolicies: {
            TaskQuestion: {
                keyFields: () => false
            },
            ModelsApplicationV1Pre_SuccessfulApplicationPreResponse: {
                keyFields: obj => {
                    if (obj?.pre?.identifiers?.id) {
                        return `ModelsApplicationV1Pre_SuccessfulApplicationPreResponse:${obj?.pre?.identifiers?.id}`;
                    }
                    if (obj?.pre?.identifiers?.legacyId) {
                        return `ModelsApplicationV1Pre_SuccessfulApplicationPreResponse:legacy-${obj?.pre?.identifiers?.legacyId}`;
                    }
                    return false;
                }
            },
            ModelsEndorsementV1_EndorsementResponse: {
                keyFields: obj => {
                    if (obj?.success?.pre?.identifiers?.policyId) {
                        return `ModelsEndorsementV1_EndorsementResponse:${obj?.success?.pre?.identifiers?.policyId}`;
                    }

                    return false;
                }
            },
            ModelsRenewalV1_RenewalResponse: {
                keyFields: obj => {
                    if (obj?.success?.pre?.identifiers?.policyId) {
                        return `ModelsRenewalV1_RenewalResponse:${obj?.success?.pre?.identifiers?.policyId}`;
                    }

                    return false;
                }
            },
            ModelsPreV1_PreResponse: {
                keyFields: obj => {
                    if (obj?.success?.pre?.identifiers?.id) {
                        return `ModelsPreV1_PreResponse:${obj?.success?.pre?.identifiers?.id}`;
                    }
                    return false;
                }
            },
            ApiDocumentdistilleryV2_SuccessfulECResponse: {
                keyFields: obj => {
                    if (obj?.identifiers?.correlationId) {
                        return `ApiDocumentdistilleryV2_SuccessfulECResponse:${obj?.identifiers?.correlationId}`;
                    }

                    return false;
                }
            }
        }
    }).restore(initialState);

    const jwtRefreshLink = refreshTokenLink();
    const logLink = errorLogLink();

    return new ApolloClient({
        cache,
        resolvers,
        typeDefs,
        link: from([logLink, jwtRefreshLink, traceLink, splitLink]),
        name: pkg.name,
        ssrMode: Boolean(ctx),
        version: pkg.version,
        connectToDevTools: shouldConnectDevTools
    });
}
