/* eslint-disable no-bitwise */
// Generate traceparent header per https://www.w3.org/TR/trace-context/#traceparent-header

function spanId() {
    // Copy pasta from https://github.com/convoyinc/tracer/blob/master/src/utils.ts
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) + 1;
}
function uuidv4() {
    // Copy pasta from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid#2117523
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function generateTraceParent(sampled = 1) {
    const flags = sampled;
    return `00-${uuidv4()}-${spanId().toString(16)}-${flags.toString(16)}`;
}
