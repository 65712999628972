import cookie from 'cookie';
// eslint-disable-next-line
import jwt_decode from 'jwt-decode';

export const resolvers = {
    Query: {
        currentUser: async () => {
            const cookies = cookie.parse(document?.cookie);
            const jwt = jwt_decode(cookies.token);

            // eslint-disable-next-line
            jwt.__typename = 'currentUser';

            return jwt;
        }
    }
};
